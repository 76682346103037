import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Stack, Divider, Link } from '@mui/material';

const TagCloud = () => {
  const data = useStaticQuery(
    graphql`
      query AllTags {
        allPodcastRssFeedEpisode {
          distinct(field: fields___tags)
        }
      }
    `,
  );

  const {
    allPodcastRssFeedEpisode: { distinct },
  } = data;

  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={0}
      mb={2}
      sx={{ flexWrap: 'wrap', gap: 1 }}
    >
      <Link href={`/podcast/episodes/`}>Alle</Link>
      {distinct.map((tag) => {
        return (
          <Link key={tag} href={`/podcast/episodes-${tag}/`}>
            #{tag}
          </Link>
        );
      })}
    </Stack>
  );
};

export default TagCloud;
