import React from 'react';
import { Typography, Grid } from '@mui/material';

import Section from '../../components/section';
import SectionTitle from '../../components/sectionTitle';

const Statement = ({ text, title, centered }) => {
  return (
    <Section>
      <SectionTitle title={title} centered={centered} />
      <Grid container>
        <Grid item xs={12} textAlign={centered && 'center'}>
          {Array.isArray(text) === true ? (
            text.map((text, i) => (
              <Typography mb={2} key={i}>
                {text}
              </Typography>
            ))
          ) : (
            <Typography>{text}</Typography>
          )}
        </Grid>
      </Grid>
    </Section>
  );
};

export default Statement;
