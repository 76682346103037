import React from 'react';
import { graphql } from 'gatsby';

import AboutUs from '../../sections/aboutus';
import Columns from '../../sections/columns/columns';
import Episodes from '../../sections/episodes';
import Hero, { Tagline, Text, Title } from '../../sections/hero/hero';
import Layout from '../../components/layout/layout';
import Statement from '../../sections/statement';

export default function IndexPage(props) {
  const backgroundImage = props.data.background.childImageSharp.gatsbyImageData;

  return (
    <Layout
      title="Digital-Beratung aus Berlin"
      pathname={props.location.pathname}
    >
      <Hero backgroundImage={backgroundImage} backgroundColor="#EFEFEF">
        <Tagline>Piela &amp; Co. Digital Consultants</Tagline>
        <Title>Das virtuelle Digital-Team für ambitionierte Versicherer</Title>
        <Text>
          Mit meinem virtuellen Team von Digital-Spezialisten arbeite ich an den
          Herausforderungen der digitalen Zukunft.
        </Text>
        <Text>
          Bereichsübergreifend entwerfen wir neue Strategien, entwickeln
          zukunftsfähige Organisationen, bauen innovative Produkte und gründen
          schlagfertige Startups.
        </Text>
      </Hero>
      <Statement
        centered
        title="Der digitale Wandel ist unsere Leidenschaft. Versicherer sind unser Fokus."
        text="Wir kommen dazu, wenn wir etwas beitragen können. Unser Fokus liegt auf mittelständischen Versicherern. Hier haben wir bereits mit einer Vielzahl an Digital-Projekten umfangreiche Erfahrungen gesammelt."
      />
      <Columns
        title="Wir sind hochmotiviert, wenn wir Geschäftsmodelle, Organisationen und Produkte auf-, aus- oder umbauen."
        columns={[
          {
            tagline: 'Strategie',
            title:
              'Wir entwerfen, prüfen und verbessern Geschäftsmodelle und Strategien',
            text: [
              'Unternehmensziele und &#8209;strategie geben die Richtung vor. Markttrends und technologische Innovationen setzen dafür den Rahmen.',
              'Für CEOs, COOs und CIOs entwickeln wir Strategien, wie sich die Potentiale des digitalen Wandels bestmöglichst nutzen lassen.',
              'Wenn es konkret wird, kommt unser Team an Bord und begleitet oder koordiniert die Kommunikation und Umsetzung dieser Strategien.',
            ],
            hidden: true,
          },
          {
            tagline: 'Menschen',
            title:
              'Wir inspirieren Teams, entwickeln Mindsets und begleiten Organisationen bei Transformationen',
            text: [
              'Themen wie Digitalisierung, Vernetzung und Transformation zwingen Unternehmen zum Umdenken. Um den Wandel zu gestalten ist das Mitnehmen und Einbeziehen der gesamten Organisation unerlässlich.                ',
              'Für Personalvorstände und Senior HR-Executives ermitteln wir die Anforderungen und Wege zur (Personal-) Organisation der Zukunft.',
              'Zusätzlich unterstützt unser Team dabei, kritische Positionen zu besetzen oder Teams und Führungskräfte in die richtige Richtung zu entwickeln.',
            ],
            hidden: true,
          },
          {
            tagline: 'Produkte',
            title:
              'Wir planen, bauen und implementieren digitale Produkte, Roboter und New-Work-Tools',
            text: [
              'Digitale Produkte und Robotic Process Automation steigern die Effizienz. Sie können intern entwickelt oder extern eingekauft werden.',
              'Fürs COOs erarbeiten wir Optionen und Empfehlungen, wie und wo digitale Werkzeuge und neue Arbeitsmethoden die Effizienz der eigenen Organisation steigern können.',
              'Bei Bedarf entwickelt und implementiert unser Team bereichsübergreifende Lösungen.',
            ],
            hidden: true,
          },
          {
            tagline: 'Startups',
            title: 'Wir gründen externe Startups und bauen Digital-Teams auf',
            text: [
              'Manchmal geht es darum, grundlegend neue Ideen und Geschäftsmodelle in einem geschützten Umfeld aufzubauen.',
              'Für CEOs und COOs ermitteln wir Markt- und Innovationschancen und bereiten Entscheidungen zur Nutzung dieser Potentiale vor.',
              'Wir stellen dann ein Digital-Team zusammen, bauen in kürzester Zeit ein Minimum Viable Product und bringen es an den Markt für erste Tests oder für weiteres Scaling-Up.',
            ],
            hidden: true,
          },
        ]}
      />
      <Statement
        centered
        title="Wir stellen Teams gezielt zusammen."
        text="Unsere Berater sind ohne Reiseaufwand vor Ort verfügbar. Sie werden speziell für den Auftrag zusammengestellt und sind Meister in der digitalen Zusammenarbeit."
      />
      <AboutUs
        title="Ich bringe gerne Menschen zusammen. Gemeinsam lösen wir komplexe Herausforderungen."
        profiles={[props.data.jonas]}
        extraTitle="In den letzten 36 Monaten habe ich 14 Projekte mit einem Team von 11 Digital-Experten erfolgreich umgesetzt"
        extraText={[
          {
            title: 'Marketing',
            text: 'Performance Marketing Specialists, Content Creators, Social Media Experts, Brand Strategists, Story Creators',
          },
          {
            title: 'Produkte',
            text: 'Product Strategists, Product Managers, Product Owners, User Experience Researcher, UX Designer, Visual Designer',
          },
          {
            title: 'Software',
            text: 'Software Architects, DevOp Engineers, Software Engineers, Security Specialists, QA Engineers, Software Engineering Leads',
          },
          {
            title: 'HR',
            text: 'Change Managers, Recruiters, HR Strategists, Workshop Moderators, Executive Coaches, Employer Branding Experts',
          },
        ]}
      />
      <Statement
        centered
        title="Wir leben das digitale Zeitalter."
        text="Als virtuelles Team nutzen wir Video-Calls und Chat, New-Work-Tools und Cloud-Software. Damit sind wir flexibler, schneller und besser auf unseren Auftrag zugeschnitten."
      />

      <Columns
        title="Was uns anders macht? Mit Fokus und einem auf das Projekt abgestimmten Team erwirken wir treffsichere Ergebnisse."
        columns={[
          {
            tagline: 'Fokus',
            title:
              'Unser Fokus sind mittelständische Versicherer. Hier haben wir unsere Expertise.',
            text: [
              'Der digitale Wandel betrifft jeden. Wir helfen dort, wo wir können.',
              'Wir sprechen gezielt mittelständische Versicherer an. Sie sind groß genug, damit wir etwas bewegen können und klein genug, damit wir etwas beitragen können.',
              'Zu unseren Kunden gehören auch Unternehmen anderer Branchen. Hier helfen wir gerne, wenn wir etwas beitragen können und die Chemie stimmt.',
            ],
            hidden: true,
          },
          {
            tagline: 'Virtuelles Team',
            title:
              'Ich stelle jedes Projektteam entsprechend der Anforderungen gezielt zusammen.',
            text: [
              'Mit im Team ist, wer etwas beitragen kann. Wir nutzen die besonderen Fähigkeiten jedes Einzelnen, um bessere Ergebnise zu erreichen.',
              'Wir sind Profis in der Remote-Zusammenarbeit. Wo möglich arbeiten wir mit Chat und Video-Calls, wo nötig sind wir europaweit vor Ort.',
              'Langjährige Zusammenarbeit und ein minutiöser Auswahlprozess sorgen für verlässliche Ergebnisse.',
            ],
            hidden: true,
          },
        ]}
      />
      <Statement
        centered
        title="Wir legen sofort los."
        text="Virtuelle Beratungen sind nicht durch starre Organisationen eingeschränkt. Wir haben unsere Leute vor Ort und jederzeit einsatzbereit."
      />
      <Episodes
        title="Im Digital Insurance Podcast rede ich mit meinen Gästen über die Herausforderungen der digitalen Transformation in der Versicherungswirtschaft."
        linkToPodcast
        limit="3"
      />
    </Layout>
  );
}
export const query = graphql`
  query MyQuery {
    background: file(relativePath: { eq: "backgrounds/bg-consulting.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    jonas: teamJson(name: { eq: "Jonas Piela" }) {
      name
      bio
      focus
      city
      country
      bookingLink
      linkedin
      email
      image {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 200, layout: CONSTRAINED)
        }
      }
    }
  }
`;
