import React, { Component } from 'react';
import { Typography, Grid, Button } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import Section from '../../components/section';
import SectionTitle from '../../components/sectionTitle/sectionTitle';

export default class ColumnsSection extends Component {
  constructor(props) {
    super();
    this.props = props;

    this.columnVisibility = [];
    props.columns.forEach((_item, i) => {
      this.columnVisibility[i] = false;
    });
    this.state = { columnVisibility: this.columnVisibility };
  }

  handleClick = (e) => {
    this.columnVisibility[e.target.id] =
      !this.state.columnVisibility[e.target.id];
    this.setState({ columnVisibility: this.columnVisibility });
  };

  render() {
    const { title, columns } = this.props;
    const { columnVisibility } = this.state;

    return (
      <Section>
        <SectionTitle>{title}</SectionTitle>
        <Grid container spacing={2}>
          {columns.map((item, i) => (
            <Grid
              item
              key={i}
              xs={12}
              sm={24 / columns.length}
              md={12 / columns.length}
            >
              <Typography variant="overline">{item.tagline}</Typography>
              <Typography variant="h5">{item.title}</Typography>
              <Button
                variant="text"
                startIcon={<ArrowDownwardIcon />}
                id={i}
                onClick={this.handleClick}
                sx={{
                  display:
                    columnVisibility[i] || !item.hidden
                      ? 'none'
                      : 'inline-flex',
                }}
              >
                Mehr
              </Button>
              <span
                style={{
                  display:
                    columnVisibility[i] || !item.hidden ? 'block' : 'none',
                }}
              >
                {Array.isArray(item.text) === true ? (
                  item.text.map((text, i) => (
                    <Typography
                      key={i}
                      dangerouslySetInnerHTML={{ __html: text }}
                      gutterBottom
                    />
                  ))
                ) : (
                  <Typography dangerouslySetInnerHTML={{ __html: item.text }} />
                )}
              </span>
            </Grid>
          ))}
        </Grid>
      </Section>
    );
  }
}
