import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Link, Grid, Typography } from '@mui/material';

import PAGES from '../../constants/pages';
import Section from '../../components/section';
import SectionTitle from '../../components/sectionTitle';
import EpisodesList from './EpisodesList';
import TagCloud from './TagCloud';

const Episodes = ({
  title,
  limit,
  tag,
  hideTags,
  linkToPodcastEpisodes,
  linkToPodcast,
  filter,
}) => {
  const data = useStaticQuery(
    graphql`
      query AllPodcastEpisodes {
        allPodcastRssFeedEpisode(
          sort: { fields: item___isoDate, order: DESC }
        ) {
          edges {
            node {
              itemImageFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 400, layout: CONSTRAINED)
                }
              }
              item {
                guid
                itunes {
                  subtitle
                }
                enclosure {
                  url
                }
                title
              }
              fields {
                tags
              }
            }
          }
        }
      }
    `,
  );

  let filteredEpisodes = filter
    ? data.allPodcastRssFeedEpisode.edges.filter(
        ({
          node: {
            item: { guid },
          },
        }) => filter.includes(guid),
      )
    : data.allPodcastRssFeedEpisode.edges;

  let episodes =
    limit > 0 ? filteredEpisodes.slice(0, limit) : filteredEpisodes;

  if (tag) {
    episodes = episodes.filter((edge) => edge.node.fields.tags?.includes(tag));
  }

  const renderTitle = () => {
    if (title) {
      return title;
    }

    if (tag) {
      return `Alle Ausgaben mit dem Tag #${tag}`;
    }

    return 'Alle bisherigen Ausgaben des Digital Insurance Podcasts';
  };

  return (
    <Section>
      <SectionTitle>{renderTitle()}</SectionTitle>
      <Grid container spacing={2}>
        {!hideTags && (
          <Grid item xs={12}>
            <TagCloud />
          </Grid>
        )}
        <EpisodesList episodes={episodes} />
        {linkToPodcastEpisodes && (
          <Grid item xs={12}>
            <Link to={PAGES.PODCAST_EPISODES.to}>
              <Typography textAlign="center">
                Alle Ausgaben des Podcasts anhören
              </Typography>
            </Link>
          </Grid>
        )}
        {linkToPodcast && (
          <Grid item xs={12}>
            <Typography textAlign="center">
              <Link to={PAGES.PODCAST.to}>Mehr zum Podcast erfahren</Link>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Section>
  );
};

export default Episodes;
