import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Link, Grid, Typography } from '@mui/material';

import { PlayButton } from '../../../components/button';

const EpisodesList = ({ episodes }) => {
  return (
    <>
      {episodes.map(
        (
          {
            node: {
              item: {
                title,
                guid,
                itunes: { subtitle },
                enclosure: { url },
              },
              itemImageFile: {
                childImageSharp: { gatsbyImageData },
              },
            },
          },
          index,
        ) => (
          <Grid container item spacing={2} xs={12} md={4} key={guid}>
            <Grid item xs={4} md={12}>
              <Link to={`/podcast/episodes/${guid}/`}>
                <GatsbyImage image={gatsbyImageData} alt={title} />
              </Link>
            </Grid>
            <Grid item xs={8} md={12}>
              <Link
                to={`/podcast/episodes/${guid}/`}
                sx={{ textDecoration: 'none' }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    marginTop: {
                      md: 2,
                    },
                    marginBottom: 2,
                  }}
                >
                  {title}
                </Typography>
              </Link>
              <Typography marginY={2}>{subtitle}</Typography>
              <PlayButton size="small" src={url} />
            </Grid>
          </Grid>
        ),
      )}
    </>
  );
};

export default EpisodesList;
