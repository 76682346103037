import React from 'react';

import { Typography, Grid, Container } from '@mui/material';
import Section from '../../components/section';
import SectionTitle from '../../components/sectionTitle';
import TeamProfile from '../../components/teamProfile';

export default function AboutUs(props) {
  return (
    <Section>
      <SectionTitle title={props.title} />
      <Container
        maxWidth="md"
        sx={{
          marginY: 6,
        }}
      >
        {props.profiles.map((profile, i) => (
          <TeamProfile
            profile={profile}
            singleProfile={props.profiles.length === 1 ? true : false}
          />
        ))}
      </Container>
      <Grid container>
        <Typography variant="h4" marginY={2}>
          {props.extraTitle}
        </Typography>

        {Array.isArray(props.extraText) === true ? (
          props.extraText.map((item, i) => {
            if (typeof item === 'object') {
              return (
                <Grid item xs={12} sm={12 / props.extraText.length} key={i}>
                  <Typography marginY={2} variant="h5">
                    {item.title}
                  </Typography>
                  <Typography>{item.text}</Typography>
                </Grid>
              );
            } else {
              return <Typography key={i}>{item}</Typography>;
            }
          })
        ) : (
          <Typography>{props.extraText}</Typography>
        )}
      </Grid>
    </Section>
  );
}
